/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1230px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #707070;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9em;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #707070;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    float: left;
    margin: 0 10px 30px 0;
  }
  img {
    margin-bottom: 5px;
  }
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-secondary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 12px;
    margin-bottom: 12px;
    border: 0;
    width: 80%;
    max-width: 680px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      max-width: 90px;
      height: auto;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.3em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }
    span.small {
      display: none;
      margin-top: 3px;
      font-size: 16px;
      font-family: 'Open Sans', Verdana;
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    a.help-center {
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;
      margin: 4px 12px 0 0;
      border-bottom: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        &:before {
          color: $theme-secondary;
        }
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-secondary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: darken($theme-secondary, 7.5%);
      }
    }
  }
}

#banner-wrap {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#efefef), to(#fff));
  background-image: linear-gradient(#efefef, #fff);
  &.corporate-accounts {
    background: #000 url('../images/bulk-discounts-banner-mobile.jpg') no-repeat 50% 50%;
    background-size: cover;
  }
  &.home {
    .cta-btn {
      width: 200px;
    }
  }
  .homepage-banner {
    width: 300px;
  }
}
#products-wrap {
  .card {
    border-top: 3px solid $theme-primary;

    &.callout-card {
      border: 1px solid $theme-primary;
    }
  }
  br {
    display: none;
  }
}
.alt-1 {
  background: $paleblue;
}

span.slashprice {
  font-size: 18px;
  color: #767676;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
  animation: fadein 2s;
  -moz-animation: fadein 2s;
  -webkit-animation: fadein 2s;
  -o-animation: fadein 2s;
  position: relative;
  opacity: 1;
  text-shadow: 0 0 0;
  &:after {
    display: block;
    content: '';
    position: absolute;
    left: -8%;
    top: 47%;
    width: 116%;
    height: 1px;
    background-color: #767676;
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}

.bg-theme {
  background: $theme-primary;
  color: #fff;
}

.job {
  a {
    border: 0;
    &:hover {
      img {
        filter: opacity(70%);
      }
    }
  }
}
.card.blue-hd {
  .card-header {
    background: #466d8f;
    color: #fff;
    font-size: 18px;
  }
}

.fw-strong {
  font-weight: 600 !important;
}
.alert-light-med {
  background-color: #f2f2f2;
  border-color: #fdfdfe;
}

#footer-cta {
  background: $theme-primary;
}
.job img {
  border-radius: 100%;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      max-width: 110px;
      height: auto;
      margin-right: 10px;
    }
    span.big {
      display: inline-block;
      font-size: 1.3em;
    }
    span.small {
      display: inline-block;
    }
  }
  #about-wrap {
    .first {
      border-right: 1px solid #ccc;
    }
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 70%;
    span.big {
      font-size: 1.5em;
    }
  }
  #header-wrap {
    border-top: 3px solid $theme-secondary;
    #header #support {
      display: block;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 65%;
    img {
      max-width: 155px;
      height: auto;
    }
  }
  #banner-wrap {
    &.corporate-accounts {
      background: #000 url('../images/bulk-discounts-banner.jpg') no-repeat 50% 50%;
      background-size: cover;
    }
    .homepage-banner {
      width: 500px;
    }
  }
  #products-wrap {
    margin-top: -40px;
    .card.callout-card {
      margin-top: -34px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header-wrap {
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
}
