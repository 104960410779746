/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v18-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v18-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v18-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v18-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v18-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v18-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v18-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v18-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v18-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v18-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v18-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v18-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v18-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v18-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v18-latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v18-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v18-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/open-sans-v18-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/open-sans-v18-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v18-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v18-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v18-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v18-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* fjalla-one-regular - latin */
@font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/fjalla-one-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/fjalla-one-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/fjalla-one-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/fjalla-one-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/fjalla-one-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/fjalla-one-v8-latin-regular.svg#FjallaOne') format('svg'); /* Legacy iOS */
}

/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/roboto-slab-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-slab-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab-v13-latin-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}

/* roboto-slab-600 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/roboto-slab-v13-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-slab-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab-v13-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab-v13-latin-600.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}

/* roboto-slab-700 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/roboto-slab-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-slab-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-slab-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-slab-v13-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-slab-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-slab-v13-latin-700.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}